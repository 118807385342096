//css
import './scss/normalize.css';
import './scss/index.scss';
import ReactDOM from 'react-dom';
import React from 'react';
import { createStore } from 'redux';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { reducers } from './app/reducers/index';
import ReduxIntlProvider from './app/ReduxIntlProvider';
import App from './app/App';

const store = createStore(
	reducers /* preloadedState, */,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const render = Component => {
	ReactDOM.render(
		<Provider store={store}>
			<ReduxIntlProvider>
				<AppContainer>
					<Component />
				</AppContainer>
			</ReduxIntlProvider>
		</Provider>,
		document.getElementById('app')
	);
};

render(App);
//HMR for react
if (module.hot) {
	module.hot.accept('./app/App', () => {
		render(App);
	});
}
