'use strict';

import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';
import About from '../components/about/About';
import Service from '../components/service/Service';
import Case from '../components/case/Case';
import Contact from '../components/contact/Contact';
import kvPng from '../../images/banner.png';
import companyLogo from '../../images/logo/companyLogo.svg';

const Home = () => {
	return (
		<div className="home">
			<div className="home__banner">
				<Banner />
				<div className="home__banner-logo">
					<div className="home__banner-logo-block">
						<div className="home__banner-logo-title">
							<img src={companyLogo} />
						</div>
					</div>
					<div className="home__banner-logo-desc">全方位智慧物聯網整合服務</div>
					<div className="home__banner-logo-kv">
						<img src={kvPng} />
					</div>
					<div className="home__banner-logo-arrow">
						<a href="#about">
							<DownOutlined
								className="home__banner-logo-arrow-color"
								style={{ fontSize: '38px', color: '#c6e6ff' }}
							/>
						</a>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="home__about" id="about">
					<About />
				</div>
				<div className="home__service" id="service">
					<Service />
				</div>
				<div className="home__case" id="case">
					<Case />
				</div>
			</div>
			<div className="home__contact" id="contact">
				<Contact />
				<Footer />
			</div>
		</div>
	);
};

export default Home;
