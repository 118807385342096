import React, { useEffect, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IntlProvider, addLocaleData } from 'react-intl';
import { changeLang } from './actions/base';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

addLocaleData([...en, ...zh]);

let messages;
export function getI18n() {
	return messages;
}
// 建立一個 context component ，並匯出給子 component 使用
const ReducerContext = createContext();
export { ReducerContext };

/* 
 呼叫 combineReducer 後的 reducers ，
 利用如果沒傳任何 action 就回傳目前的 state 來取得初始資料
*/

const ReduxIntlProvider = ({ children }) => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.app.language);
	useEffect(() => {
		let lang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
		lang = lang.toLowerCase();
		lang = lang.split('-')[0] || 'en';
		changeLang(lang, dispatch);
	}, []);
	// console.info('變動', language);
	if (language && language.messages) {
		messages = language.messages;
		return (
			<IntlProvider locale={language.locale} messages={language.messages}>
				{children}
			</IntlProvider>
		);
	} else {
		return false;
	}
};

export default ReduxIntlProvider;
