import React from 'react';
import aboutPng from '../../../images/about.png';
import './about.scss';

const About = () => {
	const screenWidth = window.screen.width;
	return (
		<div className="about">
			<div className="title">公司簡介</div>

			<div className="about__content">
				{screenWidth < 430 ? (
					<div className="about__content-img">
						<img src={aboutPng} alt="about" />
					</div>
				) : null}
				<div className="about__content-text">
					<p>我們是一家從神達電腦慧聯網體系出來成立的公司，專注於中央監控系統弱電整合。我們引領著這個行業，提供高效、可靠且全面的解決方案，以滿足客戶對於安全監控和建築自動化的需求。</p>
					<p>作為一家起源於神達電腦慧聯網體系的公司，我們承襲了神達電腦的創新基因和豐富的技術資源。我們的團隊由一群經驗豐富、技術優秀的專業人士組成，致力於為客戶提供最佳的中央監控系統和弱電整合方案。</p>
					<p>我們深刻理解，建立一個頂尖的中央監控系統需要跨越多個技術領域的專業知識和經驗。通過整合CCTV監控系統、緊急求救系統、門禁控制系統、網絡和通信機房系統等、停車管理系統等弱電系統，我們致力於提供一站式解決方案。</p>
					<p>我們的解決方案旨在提高安全性、提升工作效率，同時降低成本和風險。無論是大型企業、商業樓宇還是公共機構，我們都有能力為他們提供定制化的解決方案。</p>
					<p>透過與客戶的緊密合作，我們了解他們的特定需求和目標，並提供度身訂造的解決方案。我們與合作夥伴網絡合作，提供最先進的硬體和軟體產品，確保客戶獲得最佳的解決方案。</p>
					<p>如果您正在尋找一個可靠的中央監控系統弱電整合公司，我們將是您的理想合作夥伴。請聯繫我們，讓我們為您量身定制最適合的解決方案</p>
				</div>
				{screenWidth > 430 ? (
					<div className="about__content-img">
						<img src={aboutPng} alt="about" />
					</div>
				) : null}
			</div>
		</div>
	);
};

export default About;
