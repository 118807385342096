//從這開始新增要載入的reducer
import { combineReducers } from 'redux';
import { app } from './base';
// ex. import newReducer from './newReducer'

//將要用的reducer都放入下方object
const reducers = combineReducers({
	app: app
});

export { reducers };
