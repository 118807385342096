import React from 'react';
import logo from '../../../images/logo/logo.svg';
import './contact.scss';

const Contact = () => {
	return (
		<div className="contact">
			<div className="contact-title">聯絡我們</div>
			<div className="contact__content">
				<div className="contact__content-logo">
					<img src={logo} alt="contact" />
				</div>
				<div className="contact__content-info">
					<ul>
						<li>E-mail：service@sjsystem.com.tw</li>
						<li>電話：03-5500567</li>
						<li>傳真：03-6681994</li>
						<li>地址：竹北市光明六路東一段249號7樓</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Contact;
