import React, { useState } from 'react';
import { Drawer, Menu, Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import ReactIcon from '../../images/logo/logo.svg';

const Header = () => {
	const [active, setActive] = useState('about');
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [current, setCurrent] = useState('0');
	const [pages] = useState([
		{ label: '公司簡介', anchor: 'about' },
		{ label: '服務項目', anchor: 'service' },
		{ label: '實際案例', anchor: 'case' },
		{ label: '聯絡我們', anchor: 'contact' },
	]);

	return (
		<Layout className="layout">
			<div className="app__header">
				<div className="app__header__container">
					<div className="app__header-icon">
						<img src={ReactIcon} />
					</div>
					<div className="menu__mobile__btn" onClick={() => setDrawerOpen(true)}>
						<MenuOutlined style={{ color: '#fff', cursor: 'pointer', float: 'right' }} />
					</div>
					<div className="app__header__link header__desktop">
						<Menu
							mode="horizontal"
							onClick={e => setCurrent(e.key)}
							selectedKeys={[current]}
							style={{ minWidth: '800px', textAlign: 'center'}}
						>
							{pages.map((page, index) => (
								<Menu.Item key={index.toString()}>
									<a href={`#${page.anchor}`} rel="noopener noreferrer">
										{page.label}
									</a>
								</Menu.Item>
							))}
						</Menu>
					</div>
					<Drawer
						className="header__mobile"
						title={<div className="header-logo">Menu</div>}
						width={300}
						placement="right"
						closable={false}
						getContainer={() => document.getElementById('app')}
						onClose={() => setDrawerOpen(false)}
						visible={drawerOpen}
					>
						{pages.map((page, index) => (
							<a
								key={index}
								href={`#${page.anchor}`}
								className={`app__header__link-item ${active === page.anchor ? 'acive' : 'normal'}`}
								onClick={() => setActive(page.anchor)}
							>
								{page.label}
							</a>
						))}
					</Drawer>
				</div>
			</div>
		</Layout>
	);
};

export default Header;
