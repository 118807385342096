import React from 'react';
import { Card } from 'antd';
import service1Svg from '../../../images/service/1.svg';
import service2Svg from '../../../images/service/2.svg';
import service3Svg from '../../../images/service/3.svg';
import service4Svg from '../../../images/service/4.png';
import service5Svg from '../../../images/service/5.svg';
import service6Svg from '../../../images/service/6.svg';
import service7Svg from '../../../images/service/7.svg';
import service8Svg from '../../../images/service/8.svg';
import service9Svg from '../../../images/service/9.svg';
import service10Svg from '../../../images/service/10.svg';
import service11Svg from '../../../images/service/11.svg';
import service12Svg from '../../../images/service/12.svg';

import './service.scss';

const Service = () => {
	const { Meta } = Card;
	const services = [
		{
			img: service1Svg,
			title: '系統整合',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service2Svg,
			title: '門禁、資料庫整合',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service3Svg,
			title: 'IP Solution、 CCTV整合',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service4Svg,
			title: '世界各國軟硬體頂尖品牌臺灣區代理',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service5Svg,
			title: '綠能環控',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service6Svg,
			title: '客製化設計',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service7Svg,
			title: '停車管理系統規劃',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service8Svg,
			title: '機房/辦公室佈纜',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service9Svg,
			title: '智慧工廠系統規劃',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service10Svg,
			title: <div><div>緊急求救系統</div><div>對講機系統</div></div>,
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service11Svg,
			title: <div><div>EMS系統</div><div>充電樁</div></div>,
			desc: '描述描述描述描述描述描述描述描述描述',
		},
		{
			img: service12Svg,
			title: '儲能系統',
			desc: '描述描述描述描述描述描述描述描述描述',
		},
	];
	return (
		<div className="service">
			<div className="title">服務項目</div>
			<div className="service__content">
				{services.map((service, idx) => (
					<Card
						key={idx.toString()}
						hoverable
						className="service__content__card"
						cover={<img src={service.img} alt={service.title} />}
					>
						<Meta className="service__content__card-title" title={service.title} />
					</Card>
				))}
			</div>
		</div>
	);
};

export default Service;
