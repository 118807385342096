import React, { useState, useMemo } from 'react';
import { Divider, Carousel, Card, Select, Modal } from 'antd';
import defaultPng from '../../../images/case/defaultCase.png';
import case1 from '../../../images/case/case1.png';
import case2 from '../../../images/case/case2.png';
import case3 from '../../../images/case/case3.png';
import case4 from '../../../images/case/case4.png';
import case5 from '../../../images/case/case5.png';
import case6 from '../../../images/case/case6.png';
import case7 from '../../../images/case/case7.png';
import case8 from '../../../images/case/case8.png';
import case9 from '../../../images/case/case9.png';
import case10 from '../../../images/case/case10.png';
import case11 from '../../../images/case/case11.png';
import case12 from '../../../images/case/case12.png';
import case13 from '../../../images/case/case13.png';
import case14 from '../../../images/case/case14.png';
import case15 from '../../../images/case/case15.png';
import case16 from '../../../images/case/case16.png';
import case17 from '../../../images/case/case17.png';
import case18 from '../../../images/case/case18.png';
import case19 from '../../../images/case/case19.png';
import case20 from '../../../images/case/case20.png';
import case21 from '../../../images/case/case21.png';
import case22 from '../../../images/case/case22.png';
import case23 from '../../../images/case/case23.png';
import case24 from '../../../images/case/case24.png';
import case25 from '../../../images/case/case25.png';
import './case.scss';

const Case = () => {
	const { Option } = Select;
	const { Meta } = Card;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState({
		title: '',
		img: '',
		desc: '',
	});
	const screenWidth = window.screen.width;
	const filters = [
		{ label: '全部', value: 'all' },
		{ label: '教育機構、廟宇', value: 'edu' },
		{ label: '企業、商辦', value: 'enterprise' },
		{ label: '店面、住宅', value: 'shop' },
	];
	const cases = [
		{
			title: '北市N國小節能系統',
			desc: '建置項目：用電、照明、空調節能、環境監測',
			img: case1,
			type: 'edu',
		},
		{
			title: 'S大學微型氣象觀測站',
			desc: '建置項目：環境監測 ',
			img: case2,
			type: 'edu',
		},
		{
			title: 'E紡織公司智能會議室 ',
			desc: '建置項目：用電、照明、空調節能、環境監測建置 ',
			img: case3,
			type: 'enterprise',
		},
		{
			title: 'G科技公司空調系統 ',
			desc: '建置項目：空調系統、空調節能',
			img: case4,
			type: 'enterprise',
		},
		{
			title: 'M科技公司工廠環控系統 ',
			desc: '建置項目：用電、照明、空調節能、環境監測、設備監測建置',
			img: case5,
			type: 'enterprise',
		},
		{
			title: 'C企業股份有限公司工廠環控系統 ',
			desc: '建置項目：用電、照明、空調節能、環境監測、設備監測建置',
			img: case6,
			type: 'enterprise',
		},
		{
			title: 'M科技公司智能大樓環控系統 ',
			desc: '建置項目：用電、用水、門禁、監視、停管、多媒體、消防逃生、設備監測 ',
			img: case7,
			type: 'enterprise',
		},
		{
			title: 'R宮廟宇微型氣象觀測站',
			desc: '建置項目：環境監測 ',
			img: case8,
			type: 'edu',
		},
		{
			title: 'W電子股份有限公司環控系統 ',
			desc: '建置項目：用電、用水、門禁、監視、停管、機房、空調、環境監測',
			img: case9,
			type: 'enterprise',
		},
		{
			title: 'L實業富岡廠環控系統',
			desc: '建置項目：監視、冰櫃空調節能、環境監測建置 ',
			img: case10,
			type: 'enterprise',
		},
		{
			title: 'L實業復北旗鑑店環控系統',
			desc: '建置項目：監視、冰櫃空調節能、環境監測建置',
			img: case11,
			type: 'shop',
		},
		{
			title: 'L實業羅斯福路店環控系統',
			desc: '建置項目：監視、冰櫃空調節能、環境監測建置',
			img: case12,
			type: 'shop',
		},
		{
			title: 'G新北綜合住宅商辦活動中心環控系統',
			desc: '建置項目：用電、用水、門禁、監視、停管、多媒體、對講機、環境監測 ',
			img: case13,
			type: 'enterprise',
		},
		{
			title: 'K中國企業股份有限公司環控系統 ',
			desc: '建置項目：用電、用水、門禁、監視、停管、多媒體、對講機、設備監測 ',
			img: case14,
			type: 'enterprise',
		},
		{
			title: 'L營造工地管理智能系統 ',
			desc: '建置項目：門禁、監視、人員管理 ',
			img: case15,
			type: 'enterprise',
		},
		{
			title: 'B國小電力監控系統 ',
			desc: '建置項目：用電、環境監測 ',
			img: case16,
			type: 'edu',
		},
		{
			title: 'S宮廟宇微型氣象觀測站',
			desc: '建置項目：環境監測 ',
			img: case17,
			type: 'edu',
		},
		// 2023/12/26新增
		{
			title: 'H滷肉飯研發大樓',
			desc: '',
			img: case18,
			type: 'enterprise',
		},
		{
			title: 'B德國外商股份有限公司',
			desc: '',
			img: case19,
			type: 'enterprise',
		},
		{
			title: 'T離岸風電科技股份有限公司',
			desc: '',
			img: case20 ,
			type: 'enterprise',
		},
		{
			title: 'E股份有限公司：林口/南崁',
			desc: '',
			img: case21,
			type: 'enterprise',
		},
		{
			title: 'J股份有限公司：南港/忠孝敦化/南西一館',
			desc: '',
			img: case22,
			type: 'shop',
		},
		{
			title: 'D建設股份有限公司廣慈A標園區',
			desc: '',
			img: case23,
			type: 'shop',
		},
		{
			title: 'C長期照顧集團',
			desc: '',
			img: case24,
			type: 'enterprise',
		},
		{
			title: 'K國際食品有限公司',
			desc: '',
			img: case25,
			type: 'enterprise',
		},
	];

	const [activeItem, setActiveItem] = useState('all');
	const sliedeCount = useMemo(() => {
		const cardCount = cases.filter(item => item.type === activeItem || activeItem === 'all').length;
		return Math.ceil(cardCount / 8);
	}, [activeItem]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const renderCard = item => {
		return (
			<Card
				hoverable
				className="case__content__cardSlides__slide__card"
				cover={<img src={item.img || defaultPng} alt={item.title} />}
				onClick={() => {
					setModalContent(item);
					setIsModalVisible(true);
				}}
			>
				<Meta title={item.title} />
			</Card>
		);
	};
	const renderSlides = () => {
		const slides = [];
		for (let i = 0; i < sliedeCount; i += 1) {
			slides.push(
				<div key={i.toString()}>
					<div className="case__content__cardSlides__slide">
						{cases
							.filter(item => activeItem === 'all' || item.type === activeItem)
							.filter((item, idx) => Math.floor(idx / 8) === i)
							.map((item, idx) => renderCard(item, idx))}
					</div>
				</div>
			);
		}
		return slides;
	};

	return (
		<div className="case">
			<div className="title">實際案例</div>
			<div className="case__content">
				<div className="case__content-filter">
					{screenWidth > 430 &&
						filters.map((item, idx, arr) => (
							<div
								className="case__content-filter-item"
								onClick={() => setActiveItem(item.value)}
								style={{ color: activeItem === item.value ? '#0a131a' : '#898989' }}
								key={idx.toString()}
							>
								{item.label}
								{idx === arr.length - 1 ? null : (
									<Divider className="case__content-filter-item-divider" type="vertical" />
								)}
							</div>
						))}
					{screenWidth < 430 ? (
						<Select defaultValue={activeItem} style={{ width: 120 }} onChange={value => setActiveItem(value)}>
							{filters.map((item, idx) => (
								<Option value={item.value} key={idx.toString()}>
									{item.label}
								</Option>
							))}
						</Select>
					) : null}
				</div>
				<div className="case__content__cardSlides">
					<Carousel dots={{ className: 'case__content__cardSlides-dots' }}>{renderSlides().map(item => item)}</Carousel>
				</div>
			</div>
			<Modal title={modalContent.title} visible={isModalVisible} onCancel={handleCancel} footer={null}>
				<div className="modal-img">
					<img src={modalContent.img || defaultPng} alt={modalContent.title} />
				</div>
				<div className="modal-desc">{modalContent.desc}</div>
			</Modal>
		</div>
	);
};

export default Case;
