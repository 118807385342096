'use strict';

import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css';

import services from './config/services';
import Header from './components/Header';

//Sync
import Home from './containers/Home';


const pages = [{ path: '/', name: 'Home', component: Home }];

const App = () => {
	return (
		<Router basename={services.getContextRoot}>
			<div className="app">
				<Header />
				<Switch>
					{pages.map((page, index) => (
						<Route exact={index === 0} key={index} path={page.path} component={page.component} />
					))}
					<Redirect to={pages[0].path} />
				</Switch>
			</div>
		</Router>
	);
};

export default App;
